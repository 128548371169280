import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { currency, useApiRequest } from '../store/Common';
import { ClipLoader } from 'react-spinners';
import moment from 'moment/moment';
import { Close, SearchTwoTone } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';
import { DateRangePicker } from 'rsuite';

function PartnerOrdersPage() {

    const {postData, fetchData} = useApiRequest();
    const {afterToday} = DateRangePicker;
    const {partnerId} = useParams();
    const [page, setPage] = useState(2);
    const [partnerData, setPartnerData] = useState(null);
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const containerRef = useRef();
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState([]);
    const [bodyData, setBodyData] = useState({order: '', status: [], payment: [], store: [], start: 0, end: 0});
    const [searchTerm, setSearchTerm] = useState('');
    const [totalDocs, setTotalDocs] = useState(0);
    const [stores, setStores] = useState([]);
    const [selectedStores, setSelectedStores] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [stat, setStat] = useState({totalOrderSales: 0, totalOrderValue: 0})
    const [nonGstValue, setNonGstValue] = useState(0);
    const [shareValue, setShareValue] = useState(0);

    const debounce = (func, delay) => {
        let timer;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(context, args);
            }, delay);
        };
    };

    const getData = useCallback(async(page, body) =>{
        setIsLoading(true);
        const resp = await postData('partner/orders/'+partnerId+'/'+page, body);
        if(resp){
            setOrders((prevArray) => [...prevArray, ...resp.data.docs]);
            setPage(resp.data.nextPage);
            setTotalDocs(resp.data.totalDocs);
            setStat(resp.stat);
        }
        setIsLoading(false);
    },[postData, partnerId])

    useEffect(()=>{
        const getOneData = async() =>{
            setIsLoading(true);
            setOrders([]);
            const resp = await postData('partner/orders/'+partnerId+'/1', {order: '', status: [], payment: [], store: [], start: 0, end: 0});
            const resp1 = await fetchData('store/listcenter');
            const resp2 = await fetchData('partner/info/'+partnerId);
            if(resp2){
                setPartnerData(resp2.data);
            }
            if(resp1){
                setStores(resp1.data);
            }
            if(resp){
                setOrders(resp.data.docs);
                setPage(resp.data.nextPage);
                setTotalDocs(resp.data.totalDocs);
                setStat(resp.stat);
            }
            if(resp && resp2){
                let nongst = resp.stat.totalOrderSales / (1 + (18 / 100));
                setNonGstValue(nongst.toFixed(2));
                let shareVal = (resp2.data.share/100)*nongst;
                setShareValue(shareVal.toFixed(2));
            }
            setIsLoading(false);
        }
        getOneData();
    },[postData, fetchData, partnerId]);

    const handleScroll = useCallback(() => {
        const currentContainer = containerRef.current;
        if (currentContainer && orders.length > 0 && !isLoading) {
            const scrollOffset = currentContainer.scrollHeight - (currentContainer.scrollTop + currentContainer.clientHeight);
            if(currentContainer.scrollTop > 0 && scrollOffset >= 0 && scrollOffset < 10 && page){
                getData(page, bodyData);
            }
        }
    },[getData, isLoading, orders.length, page, bodyData])
    
    //on scroll trigger
    useEffect(() => {
        const currentContainer = containerRef.current;
        const debouncedScrollHandler = debounce(handleScroll, 500);
        currentContainer.addEventListener('scroll', debouncedScrollHandler);
        return () => {
            currentContainer.removeEventListener('scroll', debouncedScrollHandler);
        };
    }, [handleScroll]);

    const handleStore = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedStores(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleChange = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedStatus(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handlePaymentChange = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedPaymentStatus(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const resetList = () =>{
        if(isLoading){
            return;
        }
        setBodyData({order: '', status: [], payment: [], store: [], start: 0, end: 0});
        setOrders([]);
        setSelectedPaymentStatus([]);
        setSelectedStatus([]);
        setSelectedStores([]);
        setSearchTerm('');
        setSelectedDate(null);
        getData(1, {order: '', status: [], payment: [], store: [], start: 0, end: 0});
    }

    const submitSearch = (e) =>{
        e.preventDefault();
        if(isLoading){
            return;
        }
        let start = 0;
        let end = 0;
        if(selectedDate && selectedDate.length === 2){
            start = selectedDate[0].getTime()
            end = selectedDate[1].setHours(23, 59, 59, 999)
        }
        let body = {
            order: searchTerm,
            status: selectedStatus,
            payment: selectedPaymentStatus,
            store: selectedStores,
            start,
            end
        }
        setBodyData(body);
        setOrders([]);
        getData(1, body);
    }

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Stack flexDirection={'row'} justifyContent={'space-between'}>
                <Typography variant='h4'>Partner Orders ({totalDocs})</Typography>
                <Typography variant='h5'>Total Order Value: {currency}{stat.totalOrderValue}</Typography>
                <Typography variant='h5'>Total Order Sales: {currency}{stat.totalOrderSales} ({currency}{nonGstValue})</Typography>
                <Typography variant='h5'>Share: {currency}{shareValue}</Typography>
                {partnerData && <Typography variant='h4'>{partnerData.name} ({partnerData.share}%)</Typography>}
            </Stack>
            <Box component='form' p={2} m={2} sx={{background: '#fff',borderRadius: 3, boxShadow: '5px 3px 5px #00000005'}} onReset={resetList} onSubmit={submitSearch}>
                <Stack flexDirection='row' gap={2}>
                    <TextField fullWidth variant='outlined' label='Order Id/Name/Phone' value={searchTerm} onChange={(e)=>{setSearchTerm(e.target.value)}} />
                    <Stack>
                        <Box width={300}></Box>
                        <DateRangePicker format="MMM dd, yyyy" character=" - " placeholder="Select Date Range" shouldDisableDate={afterToday()} value={selectedDate} onChange={setSelectedDate} />
                    </Stack>
                    <FormControl fullWidth>
                        <InputLabel>Store</InputLabel>
                        <Select multiple value={selectedStores} onChange={handleStore} input={<OutlinedInput label="Chip" />} >
                            {stores.map(store=>{
                                return <MenuItem key={store.id} value={store.id}>{store.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select multiple value={selectedStatus} onChange={handleChange} input={<OutlinedInput label="Chip" />} >
                            <MenuItem value='Scheduled'>Scheduled</MenuItem>
                            <MenuItem value='Scheduled at Store'>Scheduled at Store</MenuItem>
                            <MenuItem value='Out for Pickup'>Out for Pickup</MenuItem>
                            <MenuItem value='Picked Up'>Picked Up</MenuItem>
                            <MenuItem value='Receiving'>Receiving</MenuItem>
                            <MenuItem value='Received'>Received</MenuItem>
                            <MenuItem value='Preparing'>Preparing</MenuItem>
                            <MenuItem value='Processing'>Processing</MenuItem>
                            <MenuItem value='Packing'>Packing</MenuItem>
                            <MenuItem value='Out for Delivery'>Out for Delivery</MenuItem>
                            <MenuItem value='Delivered at Store'>Delivered at Store</MenuItem>
                            <MenuItem value='Completed'>Completed</MenuItem>
                            <MenuItem value='Cancelled'>Cancelled</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Payment Status</InputLabel>
                        <Select multiple value={selectedPaymentStatus} onChange={handlePaymentChange} >
                            <MenuItem value='Cash'>Cash</MenuItem>
                            <MenuItem value='Cash at Store'>Cash at Pickup Store</MenuItem>
                            <MenuItem value='Card'>Card</MenuItem>
                            <MenuItem value='Online'>Online</MenuItem>
                            <MenuItem value='UPI'>UPI</MenuItem>
                            <MenuItem value='Unpaid'>Unpaid</MenuItem>
                            <MenuItem value='NA'>NA</MenuItem>
                        </Select>
                    </FormControl>
                    <IconButton type='submit'>
                        <SearchTwoTone fontSize='large' color='primary' />
                    </IconButton>
                    <IconButton type='reset'>
                        <Close fontSize='large' color='primary' />
                    </IconButton>
                </Stack>
            </Box>
            <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' paddingX={4}>
                <Box width='15%'>
                    <Typography><b>Created</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Order Id</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Store</b></Typography>
                </Box>
                <Box width='20%'>
                    <Typography><b>Customer Name</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Customer Phone</b></Typography>
                </Box>
                <Box width='5%'>
                    <Typography><b>Items</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Status</b></Typography>
                </Box>
                <Box width='5%'>
                    <Typography><b>Total</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Payment</b></Typography>
                </Box>
                <Box width='5%'>
                    <Typography><b>Details</b></Typography>
                </Box>
            </Stack>
            <Stack mt={1} p={2} pt={0} height='calc(100vh - 338px)' overflow='auto' gap={1} ref={containerRef}>
                {orders.map((order, index)=>{
                    let oName = '';
                    let oPhone = '';
                    if(order.user.name !== order.address.name){
                        oName = ` (${order.address.name})`;
                    }
                    if(order.user.phone !== order.address.phone){
                        oPhone = ` (${order.address.phone})`;
                    }
                    let payColor = order.payStatus?'green':"error";
                    return <Stack key={index} flexDirection='row' p={1.2} alignItems='center' width='100%' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                        <Box width='15%'>
                            <Typography>{moment(order.created).format('Do MMM yyyy hh:mm a')}</Typography>
                            {order.statusCode === 0 && <Typography>{moment(order.pickupTime).format('Do MMM yyyy hh:mm a')} (Pickup)</Typography>}
                        </Box>
                        <Box width='10%'>
                            <Typography>{order.orderId}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Typography>{order.store.name}</Typography>
                        </Box>
                        <Box width='20%'>
                            <Link to={`/customers/${order.user.id}`}>
                                <Typography color={'rgb(71, 85, 105)'}>{order.user.name}{oName}</Typography>
                            </Link>
                        </Box>
                        <Box width='10%'>
                            <Typography>{order.user.phone}{oPhone}</Typography>
                        </Box>
                        <Box width='5%'>
                            <Typography>{order.totalItems}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Typography>{order.status}</Typography>
                        </Box>
                        <Box width='5%'>
                            <Typography>{order.total}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Typography color={payColor}>{order.payType}</Typography>
                        </Box>
                        <Box width='5%'>
                            <Link to={`/orders/${order.id}`}>
                                <Button variant='contained' size='small'>Details</Button>
                            </Link>
                        </Box>
                    </Stack>
                })}
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Orders</Typography>
                </Stack>}
                {orders.length === 0 && !isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant='body'>No Orders to show</Typography>
                </Stack>}
            </Stack>
        </Stack>
    )
}

export default PartnerOrdersPage